import React from "react"
import { ApolloProvider } from "@apollo/client"

import { client } from "./src/apollo/client"
import { LangProvider } from "./src/context/lang-context"
import { LoadingProvider } from "./src/context/loading-context"
import { PopupProvider } from "./src/context/PopupContext"

import "./src/assets/scss/main.scss"
import "react-datepicker/dist/react-datepicker.css"

export const wrapRootElement = ({ element }) => (
  <PopupProvider>
    <LangProvider>
      <LoadingProvider>
        <ApolloProvider client={client}>{element}</ApolloProvider>
      </LoadingProvider>
    </LangProvider>
  </PopupProvider>
)
