import React, { createContext, useState, useLayoutEffect } from "react"

export const PopupContext = createContext()

export const PopupProvider = ({ children }) => {
  const [popupBanner, setPopup] = useState(true)

  useLayoutEffect(() => {
    const popupSession = window.sessionStorage.getItem("shift-cookie")
    setPopup(popupSession ? popupSession : true)
  }, [])
  return (
    <PopupContext.Provider value={{ popupBanner, setPopup }}>
      {children}
    </PopupContext.Provider>
  )
}

// import React, { createContext, useState } from "react"

// export const PopupContext = createContext(true)

// export const PopupProvider = ({ children }) => {
//   const [initialPopup, setInitialPopup] = useState(true)

//   return (
//     <PopupContext.Provider value={{ initialPopup, setInitialPopup }}>
//       {children}
//     </PopupContext.Provider>
//   )
// }
